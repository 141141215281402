$(document).ready(function(){
    $.ajaxSetup({ headers: { 'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content') } });


    function searchUsers(el){
        $.ajax({
            type: "POST",
            url: "/search",
            data: el.serialize(),
            success: function(data){
                var tar = el.parent().next(),
                    tarChilds = tar.children();

                function insertUsers(d){
                    $.each(d, function(i, a){
                        var el = $('<div class="user" data-screen-name="' + a.screen_name + '"><a class="user-content" href="#"><div class="image"><img src="' + a.profile_image_url + '"></div><div class="infos"><div class="name">' + a.name + '</div><div class="screen-name">@' + a.screen_name + '</div></div></a></div>');
                        tar.append(el);
                        el.delay(i * 20).slideDown(function(){
                            $(this).animate({
                                opacity: 1
                            }, function(){
                                el.on("click", function(e){
                                    e.preventDefault();

                                    var input = $("input[name=user_name]");

                                    el.addClass("selected");

                                    input.val(el.attr("data-screen-name"));

                                    input.parent().animate({
                                        opacity: 0
                                    }, function(){
                                        $(this).slideUp();
                                    });

                                    el.siblings().animate({
                                        opacity: 0
                                    }, function(){
                                        $(this).slideUp(function(){
                                            $(this).remove();
                                        });
                                    });

                                    setMessageLength();

                                    $(".second-section").slideDown(function(){
                                        $(this).animate({
                                            opacity: 1
                                        });
                                    });
                                });
                            });
                        });
                    });
                }

                if(!tarChilds.length)
                    return insertUsers(data);

                $.each(tarChilds, function(i){
                    $(this).animate({
                        opacity: 0
                    }, function(){
                        $(this).slideUp(function(){
                            $(this).remove();
                            if(i == tarChilds.length - 1)
                                return insertUsers(data);
                        });
                    });
                });
            }
        });
    }

    function setMessageLength(){
        var length = 140 - ($("input[name=user_name]").val().length + $("textarea[name=message]").val().length + 2),
            lengthEl = $(".message-length span"),
            submitEl = $("input[type=submit]");

        if(length < 0){
            submitEl.prop("disabled", true).stop().animate({
                opacity: 0
            }, function(){
                $(this).slideUp();
            });
        } else{
            submitEl.prop("disabled", false).stop().slideDown(function(){
                $(this).animate({
                    opacity: 1
                });
            });
        }

        if(length <= 10){
            lengthEl.addClass("red");
        } else{
            lengthEl.removeClass("red");
        }

        lengthEl.text(length);
    }

    var typingTimer,
        doneTypingInterval = 1500;

    $("input[name=user_name]").on('keyup', function(){
        clearTimeout(typingTimer);

        var el = $(this),
            val = el.val();
        typingTimer = setTimeout(function(){
            if(val.length > 3)
                searchUsers(el);
        }, doneTypingInterval);
    });

    $("textarea[name=message]").on('keyup', function(){
        setMessageLength();
    });
});